<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="please choose" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>User recharge agreement</h2>
		<p>This recharge service agreement (hereinafter referred to as "this agreement") is signed between you and the
			UniLive platform under PIX TECHNOLOGY PTE. LTD; The platform will provide services to you in accordance with the
			provisions of this agreement and the operating rules published from time to time (hereinafter referred to as "Star
			Bean Services" or "this Service")</p>
		<p>In order to provide better services to users, service users (i.e. account users who place orders to purchase
			"candy", hereinafter referred to as "you") are requested to carefully read and fully understand this
			agreement before starting to use this service. Please focus on reading the clauses that exempt or limit platform
			liability</p>
		<p>If you do not agree to this agreement, please do not proceed with any further actions (including but not limited
			to clicking recharge buttons, making payments) or use this service in any form</p>
		<p>
			The platform prohibits minors from using Star Bean services. The platform hereby kindly reminds that if you are
			the guardian of a minor, you should assume guardianship responsibilities for the minor under your guardianship.
			Please enable the youth mode and/or other minor protection tools when minors use the platform's related products
			and services, supervise and guide them to use the relevant products and services correctly, and strengthen
			restrictions and management on online payment methods to jointly create a good environment for the healthy growth
			of minors.
		</p>
		<h3>1、 Service Content</h3>
		<p>
			1. Xingdou "is a virtual tool provided by the platform to you, limited to related consumption within the platform.
			After purchasing "candy", you can use and obtain platform products or services according to the instructions
			and guidelines on the relevant pages of the platform, including but not limited to live streaming tipping services
			(users can pay for services such as live streaming performances by giving virtual gifts to the host), etc. For the
			avoidance of doubt, Xingdou service is a comprehensive network technology and related services that users pay for
			and the platform continuously provides to users. Xingdou service is not a network payment service, and "Xingdou"
			is not a token ticket, virtual currency, or prepaid voucher, and does not have monetary value or prepaid value.
		</p>
		<p>2. After purchasing "candy", you can only use it for the agreed purposes under this agreement, and cannot
			use it beyond the scope of the products/services provided by the company, nor can it be transferred between
			different UniLive accounts</p>
		<p>
			3. You should purchase "candy" through the official channels designated by the platform, and the platform
			does not recognize any unauthorized channels. If you purchase "candy" through unofficial channels, the
			platform cannot guarantee that such "candy" will smoothly enter your account, and such behavior may also be
			accompanied by risks such as fraud and money laundering, causing irreparable losses or damages to you, the
			platform, and related third parties. Therefore, purchasing through unofficial channels will be considered a
			violation, and the platform has the right to deduct or clear the "candy" in your account, restrict all or
			part of your account's functions, and even permanently ban your account. You should bear the losses caused by this
			on your own; If your violation of the aforementioned agreement causes losses to the platform or other third
			parties, you shall be responsible for compensation. If you purchase through official stores or other channels
			opened by the platform on third-party platforms such as Tmall, the platform will distribute the user information
			of the account through the "Star Bean" designated by you and/or identify the service user according to the
			agreement between you and the company (if any).
		</p>
		<p>
			4. The cost of purchasing 'candy' will be collected by the company or its designated partner. The platform
			would like to remind you that service providers from different purchasing channels may charge channel service fees
			when you make payments based on their own operational strategies. This may result in differences in the amount of
			fees for purchasing the same quantity of "candy" through different channels or for purchasing the same amount
			of "candy". Please refer to the page display when you purchase "candy" for specific details. Please pay
			attention to confirming the relevant page information and choose the "Star Bean" purchasing channel reasonably.
		</p>
		<p>5. If you intend to purchase "candy" for someone else's account, the platform will distribute the
			corresponding amount of "candy" to the account you specify. The user of this account can use the Star Bean
			services provided by the platform in accordance with the provisions of this agreement. If a dispute arises as a
			result, it shall be resolved through negotiation between you and the account user, and the platform shall not be
			liable to you or the account user for this.
		</p>
		<h3>2、 Rational consumption</h3>
		<p>1. The platform advocates rational consumption and living within one's means. Please make sure to purchase and
			use Star Bean services reasonably according to your own consumption ability and actual needs, and avoid excessive
			consumption</p>
		<p>
			2. The funds you use to purchase 'candy' should be income that you have legally obtained and have the right
			to use; If you violate this clause, you shall resolve any disputes or controversies arising therefrom on your own
			and bear the legal consequences. If your actions cause losses to the platform or third parties, you shall also
			compensate in full. If the platform discovers (including but not limited to actively discovering, receiving
			third-party complaints or notifications from competent authorities, judicial authorities, etc.) that you are
			suspected of violating the above provisions, the platform has the right to deduct or clear the "candy" in
			your account, restrict all or part of your account's functions, and even permanently ban your account; At the same
			time, the platform has the right to retain relevant information and report it to the relevant regulatory
			authorities and judicial authorities.
		</p>
		<p>
			3. The platform strictly opposes the behavior of inducing, stimulating, and inciting users to give irrational
			rewards, as well as enticing and inciting minors to give rewards with false identity information. If you discover
			the aforementioned violations, you can report them to the platform through the platform's public channels
			(reporting in the lower right corner of the live broadcast room, reporting on the UniLive live industry
			self-discipline platform, etc.), and the platform will take legal and regulatory measures to deal with them. We
			look forward to building a healthy and orderly community ecology with you.
		</p>
		<h3>3、 Your rights and obligations</h3>
		<p>1. The personal information or data you provide during the use of this service should be true and accurate, and
			you should assist and cooperate with regulatory agencies' investigations (if necessary) in accordance with legal
			regulations and platform requirements</p>
		<p>
			2. When purchasing "candy", you should carefully choose and/or enter your account information/account binding
			email, "candy" purchase quantity, etc. If your account error, incorrect quantity of "candy" or other
			purchase errors are caused by your own input errors, improper operation, insufficient understanding of billing
			methods, or other factors, resulting in your losses or expenses, the platform has the right not to make
			compensation or indemnification.
		</p>
		<p>3. You should properly keep and use your account, and be responsible for all actions and consequences under that
			account. If the platform is unable to provide this service or errors occur during the provision of this service
			due to the following circumstances, the platform shall not be held legally responsible for any losses incurred by
			you, unless otherwise required by laws and regulations:</p>
		<p>(1) Your account has become invalid, lost, stolen, or banned</p>
		<p>(2) The third-party payment institution or bank account linked to your account has been frozen, sealed, or
			otherwise abnormal, or you have used an unauthenticated account or a non personal account</p>
		<p>(3) You inform others of your account password or allow others to log in and use your account through other means
		</p>
		<p>(4) You have other circumstances of intentional or gross negligence</p>
		<p>
			4. You shall use this service in a legal and compliant manner, and shall not use it for any illegal or criminal
			purposes, violate public order and good customs, harm social morality, interfere with the normal operation of the
			platform, or infringe upon the legitimate rights and interests of third parties. Your use of this service shall
			also not violate any documents or other requirements that are binding on you (if any). The platform reminds you
			not to lend, transfer or provide your account to others in any other way, and to reasonably prevent others from
			violating the above provisions through your account, in order to protect your account and property safety.
		</p>
		<p>
			5. The platform provides refund services for minors' consumption in accordance with laws and regulations to
			protect the legitimate rights and interests of minors and their guardians; You should not use this service for
			illegal purposes or in inappropriate ways, including but not limited to adults impersonating minors to obtain
			refunds, inducing minors to consume and apply for refunds, etc. The aforementioned actions will constitute a
			serious violation of this agreement. After reasonable confirmation, the platform has the right to refuse refunds
			and reserves the right to further pursue your legal responsibilities in accordance with the law.
		</p>
		<p>
			6. You shall undertake not to use this exclusive account for any illegal activities such as money laundering, nor
			shall you accept any illegal requests from others to use you to complete money laundering through this exclusive
			account. If you violate the aforementioned agreement, the platform has the right to take measures such as
			suspending the provision of services and conducting investigations in accordance with relevant anti money
			laundering laws and regulations and/or requirements from competent authorities and judicial authorities, and shall
			not be liable for any losses or damages suffered by you as a result.
		</p>
		<p>
			7. In the process of using this service, if you are involved in related services provided by third parties (such
			as payment services, etc.), in addition to complying with the provisions of this agreement, you also need to agree
			to and comply with the agreements and relevant rules of such third parties. In any case, any disputes arising from
			the third party and the related services provided by it shall be resolved by you and the third party, and the
			platform shall not be liable to you or the third party for this.
		</p>
		<h3>4、 Platform Rights and Obligations</h3>
		<p>
			1. The platform has the right to set relevant restrictions, reminders, etc. on the "Star Bean" service from time
			to time based on considerations such as legal and regulatory revisions, regulatory requirements, transaction
			security guarantees, operational strategy updates, and changes in the market environment. This includes but is not
			limited to limiting the transaction limit and/or transaction frequency of all or part of the users, prohibiting
			specific users from using this service, or increasing transaction verification.
		</p>
		<p>
			2. To ensure transaction security, the platform also has the right to monitor your use of this service. For users
			or accounts that are reasonably identified as high-risk, the platform may take necessary measures to prevent the
			expansion of risks, protect user property and platform ecological security; These necessary measures include
			deducting or clearing the "candy" in your account, restricting all or part of your account's functions, and
			temporarily or permanently banning your account.
		</p>
		<p>
			3. When the platform discovers processing errors caused by system failures or any other reasons, whether
			beneficial to the platform or beneficial to you, the platform has the right to correct the error. At this point,
			if the actual number of "candy" you receive is less than the "candy" you should receive, the platform
			will promptly make up the difference to your account after confirming the processing error; If you actually
			receive more "candy" than you should receive, the platform will have the right to deduct the difference
			directly from your account without prior notice.
		</p>
		<p>4. The platform has the right to change, interrupt, suspend or terminate this service based on specific
			circumstances such as transaction security, operational planning, national laws and regulations, or regulatory
			requirements. The platform shall not be liable for any rights protection or infringement to you as a result</p>
		<h3>5、 Disclaimer clause</h3>
		<p>
			1. You understand and agree that this service is provided based on the current state of technology and conditions,
			and the platform will make every effort to provide you with this service to ensure its continuity and security.
			But you also acknowledge and agree that the platform cannot foresee and prevent technical and other risks at any
			time or at all times, including but not limited to service interruptions or abnormalities that may occur due to
			force majeure, network reasons, third-party service defects, etc. In the event of such a situation, the platform
			will make every commercial effort to improve it, but is not obligated to assume any legal responsibility for you
			or other third parties in this regard.
		</p>
		<p>2. The platform may carry out downtime maintenance, system upgrades, and adjustments on its own. If this results
			in you being unable to use this service normally, you agree that the platform shall not be held legally
			responsible</p>
		<p>3. Under no circumstances shall the platform be liable for any indirect, punitive, incidental, or punitive
			damages. And the platform's full responsibility to you, regardless of the reason or method, shall never exceed the
			fees you paid for using this platform</p>
		<h3>6、 Liability for breach of contract</h3>
		<p>1. If you violate this agreement, the platform has the right to take management measures against you or your
			account in accordance with laws, regulations, relevant agreements, and platform management rules, depending on the
			specific situation and degree of harm caused by your behavior, including but not limited to warning, deducting or
			clearing the "candy" in your account, restricting all or part of the functions of your account, and
			temporarily or permanently banning your account.
		</p>
		<p>
			2. You understand and acknowledge that if a third party is suspected of using your account to commit illegal or
			irregular acts or violate the provisions of this agreement, in order to protect the legitimate rights and
			interests of potential victims and other users, maintain the ecological order of the platform, and prevent further
			damage, the platform also has the right to take management measures on your account in accordance with this
			agreement and relevant rules of the platform. You should not demand that the platform bear legal responsibility
			for this.
		</p>
		<p>
			3. If you engage in or are suspected of engaging in illegal or improper behavior outside the platform, which gives
			the platform reasonable grounds to believe that you have already or will soon violate this agreement or other
			rules of the platform that may be used against you (such as the UniLive User Service Agreement, Community Self
			Discipline Convention, etc.), the platform also has the right to take the management measures stipulated in
			paragraph 1 of this article against you, and you should not demand that the platform bear legal responsibility for
			this.
		</p>




	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/RechargeAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/RechargeAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/RechargeAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/RechargeAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/RechargeAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/RechargeAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/RechargeAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/RechargeAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/RechargeAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/RechargeAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/RechargeAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/RechargeAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>